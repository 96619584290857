<template>
<page-wrapper>
  <v-row dense>
    <v-spacer></v-spacer>
    <v-col cols="5">
      <job-select v-model="jobID" add-all-option add-overheads-option></job-select>
    </v-col>
    <v-col cols="2">
      <employment-status-select v-model="userStatus" @change="refresh"></employment-status-select>
    </v-col>
    <v-col cols="5">
      <date-range @change="dateRangeChanged"></date-range>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <div v-if="allJobCosts && allJobCosts.length === 0" class="text-center font-italic">
    Nothing to display
  </div>
  <v-progress-linear v-else-if="!loaded" indeterminate></v-progress-linear>
  <div v-else v-for="{ job, rows, totals, purchases } in jobCosts" :key="job.number" class="ma-4">
    <div class="d-flex ma-2">
      <h3><span class="font-weight-regular">{{ job.number }}</span> {{ job.name }}</h3>
      <v-spacer></v-spacer>
      <file-download color="gray" small end-point="/costs/export" :params="{ job_number: job.number, ...dateRange }">
        Download
      </file-download>
    </div>
    <div v-if="purchases.paid > 0" class="d-flex ma-2">
      <b>Purchases</b>:
      £{{ purchases.paid.toLocaleString() }} paid,
      £{{ purchases.marked_up.toLocaleString() }} marked up
    </div>
    <v-simple-table dense class="cost-table mb-10">
      <thead>
      <tr>
        <th>Name</th>
        <th>Workshop hours
        <th>Recce/Travel days</th>
        <th>Install days</th>
        <th>Shoot days</th>
        <th>Shoot OT hours</th>
        <th>Cost exc. OT</th>
        <th>Cost inc. OT</th>
        <th v-if="userInFinance">Actual cost</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="user in rows" :key="`${job.number}+${user.name}`">
        <td>{{ user.name }}</td>
        <td>{{ user.workshop_hours }}</td>
        <td>{{ user.recce_travel_days }}</td>
        <td>{{ user.install_days }}</td>
        <td>{{ user.shoot_days }}</td>
        <td>{{ user.shoot_ot_hours }}</td>
        <td>£ {{ user.cost_exc_ot.toLocaleString() }}</td>
        <td>£ {{ user.cost_inc_ot.toLocaleString() }}</td>
        <td v-if="userInFinance">£ {{ user.actual_cost.toLocaleString() }}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <th>Total</th>
        <th>{{ totals.workshop_hours }} hour{{ totals.workshop_hours === 1 ? '' : 's' }}</th>
        <th>{{ totals.recce_travel_days }} day{{ totals.shoot_days === 1 ? '' : 's' }}</th>
        <th>{{ totals.install_days }} day{{ totals.install_days === 1 ? '' : 's' }}</th>
        <th>{{ totals.shoot_days }} day{{ totals.shoot_days === 1 ? '' : 's' }}</th>
        <th>{{ totals.shoot_ot_hours }} hour{{ totals.shoot_ot_hours === 1 ? '' : 's' }}</th>
        <th>£ {{ totals.cost_exc_ot.toLocaleString() }}</th>
        <th>£ {{ totals.cost_inc_ot.toLocaleString() }}</th>
        <th v-if="userInFinance">£ {{ totals.actual_cost.toLocaleString() }}</th>
      </tr>
      </tfoot>
    </v-simple-table>
  </div>
</page-wrapper>
</template>

<script>
import PageWrapper from '../components/ui/PageWrapper'
import * as api from '../api'
import JobSelect from '../components/ui/JobSelect'
import FileDownload from '../components/export/FileDownload'
import { mapGetters } from 'vuex'
import DateRange from '../components/ui/DateRange'
import { MAX_OVERHEAD_JOB_NUMBER } from '../data'
import EmploymentStatusSelect from '../components/ui/EmploymentStatusSelect'

export default {
  name: 'Costs',
  components: { EmploymentStatusSelect, DateRange, JobSelect, PageWrapper, FileDownload },
  data() {
    return {
      jobID: 0,
      allJobCosts: [],
      dateRange: { start: null, end: null },
      userStatus: null
    }
  },
  computed: {
    ...mapGetters(['userInFinance']),
    jobCosts() {
      if (this.jobID === 0) {
        return this.allJobCosts
      } else if (this.jobID === MAX_OVERHEAD_JOB_NUMBER) {
        return this.allJobCosts.filter(jc => +jc.job.number < MAX_OVERHEAD_JOB_NUMBER)
      }
      return this.allJobCosts.filter(jc => jc.job.id === this.jobID)
    },
    loaded() {
      return this.allJobCosts && this.allJobCosts.length > 0
    }
  },
  methods: {
    async dateRangeChanged({ start, end }) {
      this.dateRange = { start, end }
      await this.refresh()
    },
    async refresh() {
      this.allJobCosts = null
      const { start, end } = this.dateRange
      const user_status = this.userStatus
      this.allJobCosts = await api.get('/costs', { start, end, user_status })
    }
  },
  async mounted() {
    this.allJobCosts = await api.get('/costs')
  }
}
</script>

<style scoped lang="scss">
.cost-table {
  td {
    //width: 16%;
  }
  tfoot tr th {
    font-size: 0.9rem !important;
  }
}
</style>
